<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ LibraryPolicyType </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>LibraryPolicyType</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Add New LibraryPolicyType</label>
                             <v-text-field class="text" v-model="libraryPolicyType" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                          
                            <v-btn color="success" @click="addlibraryPolicyType">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit LibraryPolicyType
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">LibraryPolicyType </label>
                                     <v-text-field class="text" v-model="editedLibraryPolicyType" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                           
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" color="error" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" color="success" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <template v-if="libraryPolicyType_list!=null">
                    <v-data-table :headers="headers" :items="libraryPolicyType_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateLibraryPolicyType(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.isactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.actions1="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem1(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.isdefault" @change="editItem1(item)"></v-switch>
                        </template>
                         <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteLibraryPolicyType(item)"> mdi mdi-delete</v-icon>
                        </template> 
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            libraryPolicyType: null,
            libraryPolicyType_list:[],
            editedLibraryPolicyType_list: null,
            editedLPolicyType:null,
            editedLibraryPolicyType:null,
            editedLibraryPolicyTypeId:null,
            Isactive:"",
            Isdefault:"",
            edit_btn_load: false,
            rules: {
                required: (value) => !!value || "Required.",
            },
            accountStatus_list: null,
            headers: [
                { text: "Sr. No.", value: "srno" },
                { text: "Type", value: "name" },
                { text: "Edit", value: "edit" },
                { text: "Is active", value: "actions", sortable: false },
                { text: "Is default", value: "actions1", sortable: false },
                { text: "Delete", value: "delete" },
            ],
        }),
        watch: {},
        methods: {
             addlibraryPolicyType() {
               //alert("add: "+this.libraryPolicyType);
                const data = {
                    libraryPolicyType_name: this.libraryPolicyType,
                };
                if (this.libraryPolicyType) {
                    axios
                        .post("/admin/addLibraryPolicyType", data)
                        .then((res) => {
                            this.org = res.data.org;
                            this.libraryPolicyType_list = res.data.libraryPolicyType_list;
                            if (res.data.msg == "success") {
                                this.showSnackbar("#4CAF50", "Library PolicyType Added successfully...");
                                this.libraryPolicyType = "";
                                this.userType = null;
                                this.$refs.form.reset()
                                    this.onload();
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                                this.onload();
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", " Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Account status");
                    this.$refs.form.validate()
                }
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
               // alert(item.Isactive);
                 const data = { 
                        Isactive:item.isactive,
                        id:item.id
                };
                axios
                    .post("/admin/LibraryPolicyTypeIsactive", data)
                    .then((res) => {
                         if (res.data.msg == "200") {
                                this.showSnackbar("#4CAF50",  "Is active successfully");
                               this.onload();
                            }
                        
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", " Something went wrong");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

editItem1(item) {
               // alert(item.isdefault);
                 const data = { 
                        Isdefault:item.isdefault,
                        id:item.id
                };
                axios
                    .post("/admin/LibraryPolicyTypeIsdefault", data)
                    .then((res) => {
                         if (res.data.msg == "200") {
                                this.showSnackbar("#4CAF50",  "Is default successfully");
                               this.onload();
                            }
                        
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", " Something went wrong");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },




            updateLibraryPolicyType(item) {
                // alert(item.name);
                this.dialog = true;
                this.editedLibraryPolicyType = item.name;
                this.editedLibraryPolicyTypeId = item.id;
            },

            saveEdit(item) {
            //    alert(this.editedFrequency);
                const data = {
                    name: this.editedLibraryPolicyType,
                    id: this.editedLibraryPolicyTypeId,
                };
               
                    axios
                        .post("/admin/editLibraryPolicyType", data)
                        .then((res) => {
                            if (res.data.code == "200") {
                            // this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "LibraryPolicyType updated successfully...");
                            this.onload();
                            this.dialog = false;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", " Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            this.edit_btn_load = false;
                            // this.editUserType = null;
                            axios;
                        });
                },
            
            deleteLibraryPolicyType(item){                
                const data = {            
                     deleteitem: item.id
                };
                axios
                    .post("/admin/deleteLibraryPolicyType", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "LibraryPolicyType Deleted Successfully  !!");
                            this.onload();
                        }
                         else if(res.data.msg == "exp"){
                           this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                       }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", " Something went wrong!!!");
                        window.console.log(error);
                    });
            },

            onload() {
                this.overlay = true
                axios
                    .post("/admin/getLibraryPolicyType")
                    .then((res) => {
                        this.org = res.data.org;
                        this.libraryPolicyType_list = res.data.libraryPolicyType_list;                    

                        this.overlay = false
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", " Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

           
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>
